var instagram = [];
var images_loaded = 0;
var insta_to_get = 8;

var media = [],
  i = 0,
  m = 0,
  endString = "",
  iLength = 1,
  loadString = "",
  loadCount = iLength,
  loadCIndex = 1,
  position = 0,
  totalLength = 0,
  noLoad = false,
  percent_loaded = 0,
  insta_to_show = 18;



function printInsta(insta) {
  var textString = '',
    description = '';
  var thisDetails = '',
    n_classes = '';
  //var image_num = i + 1;
  //
  if (insta.description.length > 60) {
    description = insta.description.substring(0, 60) + '...';
  } else {
    description = insta.description;
  }

  if (insta.is_video) {
    var extra_classes = '';
    if (insta.landscape) {
      extra_classes = 'landscape';
    }
    textString = "<div class='ig video " + extra_classes + "'>";
    textString += "<div class='likes'><b>" + insta.likes + " Likes</b></div>";
    textString += "<div class='inner'>";
    textString += "<a href='" + insta.url + "' target='_blank'>";
    textString += "<video loop autoplay muted>";
    textString += "<source src='" + insta.image + "'/>";
    textString += "</video>";
    textString += "</a>";
    textString += "</div>";
    textString += "</div>";
  } else {
    textString = "<div class='ig'>";
    textString += "<div class='likes'><b>" + insta.likes + " Likes</b></div>";
    textString += "<div class='inner'>";
    //textString += "<div class='like'>" + insta.likes + " Likes</div>";
    textString += "<a href='" + insta.url + "' target='_blank'><img src='" + insta.image + "' class='imgType_" + insta.type + "' alt='" + description + "'></a>";
    textString += "</div>";
    textString += "</div>";
  }
  jQuery("#insta_wrapper").append(textString);
}


function showImages() {
  instagram.forEach(function(insta) {
    printInsta(insta);
    i++;
  });

  jQuery("#insta_wrapper").append('<div class="clearfix"></div>');

  jQuery(".instagram").addClass("loaded");
}




function getInstagram() {

  jQuery("#insta_wrapper").html("");


  /*
  for (var i = 0; i < 4; i++) {
    var mediaSrc = '';
    var is_video = 0;
    if (i % 4 === 0) {
      mediaSrc = 'https://scontent-lga3-1.cdninstagram.com/t51.2885-15/e35/13259093_1064409046959194_1411976440_n.jpg?ig_cache_key=MTI2MDM4Nzc0Mjk3Mjc0OTg2NA%3D%3D.2';
    } else if (i % 4 === 1) {
      mediaSrc = 'https://scontent-lga3-1.cdninstagram.com/t51.2885-15/e35/13259109_1718596131748529_1442468666_n.jpg?ig_cache_key=MTI1ODk0MjEyMjUyMTExMDc2OQ%3D%3D.2';
    } else if (i % 4 === 2) {
      mediaSrc = 'https://scontent-lga3-1.cdninstagram.com/t51.2885-15/e35/12826140_1567263400251044_413676885_n.jpg?ig_cache_key=MTIwODA0MTAyODAyNzAwMDg4Ng%3D%3D.2';
    } else {
      mediaSrc = 'https://scontent-lga3-1.cdninstagram.com/t51.2885-15/s480x480/e35/13277754_603214413170537_770283413_n.jpg?ig_cache_key=MTI1ODA2OTMxMjE0MzczMTUwNQ%3D%3D.2';
    }
    var captionText = 'Yeah dude. This is some really premo type shit gotta get it yeah. Yeah dude. This is some really premo type shit gotta get it yeah.';
    var n = 'http://www.instagram.com';
    var newShot = {
      "life_type": "insta",
      "sdate": "2014-08-24 04:34:57Z",
      "type": "i",
      "imgID": "1234",
      "is_video": is_video,
      "image": mediaSrc,
      "poster": 'wot',
      "timestamp": '2014-07-23 00:00:00',
      "description": captionText,
      "user": 'pod6isblowedup',
      "url": n.link,
      "usernameLink": "http://instagram.com/pod6isblowedup",
      "likes": "23"
    };
    life.push(newShot);
    percent_loaded += 2.3;
    jQuery(".progress_bar .active").css("width", percent_loaded + "%");
  }


  // We're done. Show stuff.
  showImages();
  */



  jQuery.get(
    "https://api.instagram.com/v1/users/" + vars.insta_uid + "/media/recent/?access_token=" + vars.insta_token + "&count=" + insta_to_get,
    function(val) {

      if (val.data) {
        val.data.forEach(function(insta) {
          var isVideo = '';

          if (insta.caption == null) {
            captionText = "";
          } else {
            captionText = insta.caption.text;
          }
          if (insta.videos == null) {
            //mediaSrc = insta.images.thumbnail.url.replace('s150x150/', 's640x640/');
            mediaSrc = insta.images.standard_resolution.url;
            isVideo = 0;
          } else {
            mediaSrc = insta.videos.standard_resolution.url;
            isVideo = 1;
            if (insta.videos.standard_resolution.height === 360) {
              landscape = true;
            }
          }

          var utcSeconds = insta.created_time;
          var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
          d.setUTCSeconds(utcSeconds);
          var sdate = d.getFullYear() + "-" + pad(d.getMonth() + 1, 2) + "-" + pad(d.getDate(), 2) + " " + pad(d.getHours(), 2) + ":" + pad(d.getMinutes(), 2) + ":" + pad(d.getSeconds(), 2) + "Z";

          var newShot = {
            "sdate": sdate,
            "type": "i",
            "imgID": m,
            "is_video": isVideo,
            "image": mediaSrc,
            "poster": insta.images.standard_resolution.url,
            "timestamp": insta.created_time,
            "description": captionText,
            "user": insta.user.full_name,
            "url": insta.link,
            "likes": insta.likes.count
          };
          instagram.push(newShot);


          images_loaded++;
        });
        // We're done. Show stuff.
        showImages();
      }
    },
    "jsonp"
  ).fail(function() {
    console.log("Instagram call failed.");
  });

  /*

  jQuery.get(
    //"https://api.instagram.com/v1/users/1701007557/media/recent/?access_token=1701007557.8c3f7fb.9143743710ae43e69788cc7a81bc793f&count="+insta_to_get,
    "https://api.instagram.com/v1/users/6120299872/media/recent/?access_token=6120299872.12245e3.35064546e52c452c869bce093175fca0&count=" + insta_to_get,
    function(val) {
      if (val.data) {
        val.data.forEach(function(insta) {

          var landscape = false;

          if (insta.caption == null) {
            captionText = "";
          } else {
            captionText = insta.caption.text;
          }
          if (insta.videos == null) {
            mediaSrc = insta.images.standard_resolution.url;
            isVideo = 0;
          } else {
            mediaSrc = insta.videos.standard_resolution.url;
            isVideo = 1;
            console.log(insta);
            if (insta.videos.standard_resolution.height === 360) {
              landscape = true;
            }
          }

          var utcSeconds = insta.created_time;
          var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
          d.setUTCSeconds(utcSeconds);

          var sdate = d.getFullYear() + "-" + pad(d.getMonth() + 1, 2) + "-" + pad(d.getDate(), 2) + " " + pad(d.getHours(), 2) + ":" + pad(d.getMinutes(), 2) + ":" + pad(d.getSeconds(), 2) + "Z";

          var newShot = {
            "life_type": "insta",
            "sdate": sdate,
            "type": "i",
            "imgID": m,
            "is_video": isVideo,
            "image": mediaSrc,
            "poster": insta.images.standard_resolution.url,
            "timestamp": insta.created_time,
            "description": captionText,
            "user": insta.user.full_name,
            "url": insta.link,
            "usernameLink": "http://instagram.com/greatflatsbrewing",
            "likes": insta.likes.count,
            "landscape": landscape
          };

          instagram.push(newShot);


          images_loaded++;
        });





        // We're done. Show stuff.
        showImages();

      } else {
        console.log('No data.');
      }

    },
    "jsonp"
  );

*/





}